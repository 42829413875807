import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import BaseComponent from '../../shared/base/base.component';
import { UserService } from '../../services/users/user.service';
import { Cookies } from '../../helpers/Cookies';
import { ActivatedRoute } from '@angular/router';
import { CurrentUser } from '../../helpers/CurrentUser';
import store from 'store';
import { PauseService } from '../../services/pause/pause.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ChangePasswordComponent } from './change-password/change-password.component';
import _get from 'lodash/get';
import { UserInfoService } from '../../services/user-info/user-info.service';
import { quizTypes } from 'src/app/constants/quizzes';
import { InfoV2Service } from 'src/app/services/infoV2/infoV2.service';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {

  public loader: boolean;

  public login: FormGroup;
  public qrForm: FormGroup;

  public shownQr: false;

  public user: any;

  public ip: any = 'broke';

  public selectedTab = 0;
  public qrCode;
  public firstResponse;

  public passwordExpired = false;

  public adminApproveChangePassword = true;

  constructor(private userService: UserService,
    private pauseService: PauseService,
    private userInfoService: UserInfoService,
    private infoV2Service: InfoV2Service,
    private route: ActivatedRoute,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
    super();
    this.userService.ip().subscribe((res) => {
      if (res.ip) {
        this.ip = res.ip;
      }
    });
  }

  ngOnInit() {

    this.updateStoreBeforeLogin();

    this.route
      .queryParams
      .subscribe(params => {
        if (params['agent_token']) {
          // Defaults to 0 if no query param provided.

          if (params['manager_id']) {
            store.set('manager_id', (params['manager_id']));
          }

          this.login_by_token(params['agent_token']);
        }
      });

    if (Cookies.get('token') && store.get('user')) {
      location.href = '/dashboard';
    }

    this.login = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(1)
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(4)

      ])
    });

    this.qrForm = new FormGroup({
      passcode: new FormControl('', Validators.required)
    });
  }

  public logIn(event) {
    event.preventDefault();

    if (!this.login.valid) {
      return;
    }
    this.loader = true;

    this.authUser();
  }


  private updateStoreBeforeLogin() {
    store.set('view_referral_announcement', true);

  }


  public login_by_token(token) {
    this.userService.login_by_token({
      body: Object.assign({ token })
    }).subscribe((response) => {
      store.remove(quizTypes.onLogin);
      store.remove(quizTypes.onHold);
      store.remove(quizTypes.onCent);
      store.remove('quizzesToDo');
      store.remove('quizSave');
      store.remove('reschedule');

      store.remove('agent_on_call');
      store.remove('call_data');
      store.remove('call_answer');
      //
      store.set('device-status', response.device);
      store.set('role_commissions', response.role_commissions);
      store.set('personal_daily_commissions', response.personal_daily_commissions);
      store.set('wizard_type', response.wizard_type);
      store.set('wizard_gmt_setting', response.wizard_gmt_setting ? response.wizard_gmt_setting : ['all']);
      store.set('gmt_wizards', response.wizard_gmt_setting ? '' : 'all');

      store.set('wizard_info', response.wizard_info ? response.wizard_info : {});

      Cookies.set('centrifugo', response.user.cent_token, 30);
      Cookies.set('token', response.user.token, 30);

      CurrentUser.setUser(response.user);

      this.onSelectLang(response.user.language);

      this.getModelInfo();
      this.getGroupFieldsInfo();
      this.getUserInfo().then(() => {
        this.goToDashboard();
      });
    }, (response) => {
      this.login.setErrors({
        general: response.error.message
      });
      this.loader = false;
    });
  }

  public fa2Authentication() {
    this.loader = true;

    const body = {
      'token': this.qrForm.get('passcode').value,
      'user_id': this.firstResponse.user.id
    };

    this.userService.validate_2fa({ body }).subscribe(res => {
      Cookies.set('token', res.user.token, 30);

      this.firstResponse.user.token = res.user.token;
      CurrentUser.setUser(this.firstResponse.user);

      this.onSelectLang(res.user.language);

      this.getUserInfo().then(() => {
        this.goToDashboard();
      });
    }, (error) => {
      this.qrForm.setErrors({
        general: error.error.error[0].toUpperCase() + error.error.error.slice(1)
      });
      this.loader = false;
    });
  }

  public onSelectLang(langs: string[]): void {
    if (langs && langs.length === 1) {
      const lang = langs[0];

      this.translateService.use(lang);

      localStorage.setItem('lang', lang);
    }
  }

  public changePassword(userId = 0) {

    this.dialog.open(ChangePasswordComponent, {
      data: {
        userId: userId ? userId : this.firstResponse.user.id,
        isRestore: !!userId
      }
    }).afterClosed().subscribe(res => {
      if (res.qr_code) {
        if (!!userId) {
          this.snack.open('The password was changed successfully.', 'Ok!', {
            duration: 3000,
          });
          this.userService.logout().subscribe(logRes => {
            Cookies.set('token', '', -1);
            this.login.reset();
          });
        } else {
          this.selectedTab = 1;

          this.qrCode = res.qr_code;
          setTimeout(() => {
            (<any>document.getElementById('qr')).src = 'data:image/png;base64,' + this.qrCode;
          }, 0);
        }
      } else {
        Cookies.set('token', res.user.token, 30);
        CurrentUser.setUser(res.user);

        if (!!userId) {
          this.snack.open('The password was changed successfully.', 'Ok!', {
            duration: 3000,
          });
          this.userService.logout().subscribe(logRes => {
            Cookies.set('token', '', -1);
            this.login.reset();
          });
        } else {
          this.goToDashboard();
        }
      }

    });
  }

  private goToDashboard() {
    this.pauseService.getCallSystems({}).subscribe((result) => {
      store.set('call-systems', result);
      store.set(quizTypes.onLogin, true);
      location.href = '/dashboard';
    }, (error) => {
      // console.log(error);
      store.set(quizTypes.onLogin, true);
      location.href = '/dashboard';
    });
  }

  public requestQrCode() {
    if (this.user === null) {
      this.snack.open('The user not found', 'Ok!', {
        duration: 3000,
      });

      return;
    }

    this.userService.requestQrCode(this.user.id).subscribe(res => {
      if (res) {
        this.snack.open('The QR Code is requested!', 'Ok!', {
          duration: 3000,
        });
      }
    }, err => {
      this.snack.open(_get(err, ['error', 'error'], 'Something Went Wrong'), 'Ok!', {
        duration: 3000,
      });
    });
  }

  public restorePassword() {
    this.userService.requestRestorePassword(this.login.value).subscribe(res => {
      if (res) {
        switch (res.restore_password_status) {
          case 1:
            this.snack.open('The request for change password was already sent to the admin.', 'OK!', {
              duration: 3000,
            });
            break;
          case 2:
            this.snack.open('The request for change password was already sent to the admin. Please contact your manager.', 'OK!', {
              duration: 3000,
            });
            break;
          case 3:
            this.changePassword(res.user_id);
            break;
        }
      }
    }, err => {
      this.snack.open(_get(err, ['error', 'error'], 'Something Went Wrong'), 'Ok!', {
        duration: 3000,
      });
    });
  }

  private authUser() {
    this.passwordExpired = false;

    this.userService.login({
      body: Object.assign({
        ip: this.ip,
      }, this.login.value)
    }).toPromise().then((response) => {
      store.remove('reschedule');

      this.shownQr = _get(response, ['user', 'shown_qr'], false);

      this.user = _get(response, ['user'], null);

      store.set('device-status', response.device);
      store.set('role_commissions', response.role_commissions);
      store.set('personal_daily_commissions', response.personal_daily_commissions);
      store.set('wizard_type', response.wizard_type);
      store.set('wizard_gmt_setting', response.wizard_gmt_setting ? response.wizard_gmt_setting : ['all']);
      store.set('gmt_wizards', response.wizard_gmt_setting ? '' : 'all');
      store.set('wizard_info', response.wizard_info ? response.wizard_info : {});

      store.remove('agent_on_call');
      store.remove('call_data');
      store.remove('call_answer');

      this.onSelectLang(response.user.language);

      this.getModelInfo();
      this.getGroupFieldsInfo();

      Cookies.set('centrifugo', response.user.cent_token, 30);

      if (response.hasOwnProperty('is_password_relevant') && !response.is_password_relevant) {
        this.firstResponse = response;
        this.passwordExpired = true;
        this.loader = false;
        return;
      }

      if (response.hasOwnProperty('approve_change_password') && !response.approve_change_password) {
        this.firstResponse = response;
        this.adminApproveChangePassword = true;
        this.loader = false;
        return;
      }

      if (!response.user.token && response.qr_code) {
        this.firstResponse = response;

        this.loader = false;
        this.selectedTab = 1;

        this.qrCode = response.qr_code;
        setTimeout(() => {
          (<any>document.getElementById('qr')).src = 'data:image/png;base64,' + this.qrCode;
        }, 0);

      } else {
        Cookies.set('token', response.user.token, 30);
        CurrentUser.setUser(response.user);


        this.getUserInfo().then(() => {
          this.goToDashboard();
        });
      }

    }, (response) => {
      this.login.setErrors({
        general: response.error.message
      });
      this.loader = false;
    });
  }

  public getModelInfo() {
    this.userService.modelInfo().subscribe(res => {
      if (res) {
        const modelInfoHash = store.get('hash_model_info');
        if (res.hash === modelInfoHash) {
          return;
        }
        for (const key in res) {
          store.set(key + '_model_info', res[key]);
        }
      }
    });
  }

  public getGroupFieldsInfo() {
    if(environment.name.includes('prod')) {
      this.infoV2Service.groupFieldsInfo().subscribe(res => {
        if (res) {
          const modelInfoHash = store.get('hash_group_fields');
          if (res.hash === modelInfoHash) {
            return;
          }
          for (const key in res) {
            store.set(key + '_group_fields', res[key]);
          }
        }
      });
    } else {
      this.userService.groupFieldsInfo().subscribe(res => {
        if (res) {
          const modelInfoHash = store.get('hash_group_fields');
          if (res.hash === modelInfoHash) {
            return;
          }
          for (const key in res) {
            store.set(key + '_group_fields', res[key]);
          }
        }
      })
    }
  }

  public async getUserInfo() {
    await Promise.all([this.getSettings(), this.getPermissions(), this.getAutocompletes(), this.getUsers(), this.getNewPermissions()]);
  }

  public getSettings(): Promise<{}> {
    return new Promise(resolve => {
      this.userInfoService.getSettings().subscribe(res => {
        store.set('settings', res);
        resolve(true);
      });
    });
  }

  public getPermissions(): Promise<{}> {
    return new Promise(resolve => {
      this.userInfoService.getPermissions().subscribe(res => {
        store.set('permissions', res);

        resolve(true);
      });
    });
  }

  public getNewPermissions(): Promise<{}> {
    return new Promise(resolve => {
      this.userInfoService.getNewPermissions().subscribe(res => {
        store.set('common_settings', res);

        resolve(true);
      });
    });
  }

  public getAutocompletes(): Promise<{}> {
    return new Promise(resolve => {
      this.userInfoService.getAutocompletes().subscribe(res => {
        store.set('autocomplete', res);

        resolve(true);
      }, () => {
        resolve(true);
      });
    });
  }

  public getUsers(): Promise<{}> {
    return new Promise(resolve => {
      this.userInfoService.getUsers().subscribe((res: any[]) => {
        for (let i = 0; i < res.length; i++) {
          if (res[i].alias_names !== undefined && res[i].alias_names.length > 0) {
            const uniqueAliasNames = Array.from(new Set(res[i].alias_names));
            res[i].full_alias_name = res[i].full_name + `(${uniqueAliasNames.join(', ')})`;
          }
        }
        store.set('users', res);
        resolve(true);
      }, () => {
        resolve(true);
      });
    });
  }
}
