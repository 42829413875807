import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import store from 'store';
import storage from 'store';

import { UserService } from 'src/app/services/users/user.service';
import { QuizPopupComponent } from 'src/app/components/quiz-popup/quiz-popup.component';
import { Observable } from 'rxjs';
import { PauseService } from 'src/app/services/pause/pause.service';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { LockBarSelectPauseComponent } from './lock-bar-select-pause/lock-bar-select-pause.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-lock-bar',
  templateUrl: './lock-bar.component.html',
  styleUrls: ['./lock-bar.component.scss'],
  animations: [
    trigger('fadeIn', [
        transition(':enter', [
          style({ height: '0px', opacity: 0.5 }),
          animate('200ms ease-in', style({ height: '14px', opacity: 1 }))
        ]),
      ]),
      trigger('fadeOut', [
        transition(':leave', [
          animate(100, style({ height: '0px', opacity: 0 }))
        ]),
      ])
  ]
})
export class LockBarComponent implements OnInit, OnDestroy {

  public listener: any;

  public subscribes: any = {};

  public away: any;

  public lock: any = storage.get('away');


  // Timer data
  public ticks = 0;

  public minutesDisplay: number = 0;
  public hoursDisplay: number = 0;
  public secondsDisplay: number = 0;

  @ViewChild('input') public input: any;

  public disabled: any = false;

  public pauseReason: any = {
    title: store.get('pause-title')
  };

  constructor(
    private storeNgrx: Store<any>,
    private user: UserService,
    private pauseService: PauseService,
    private quizService: QuizService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
  ) { }

  ngOnInit() {
    this.listenStatusChange();

    if(this.lock) {
      this.startTimer();
    }
  }

  private listenStatusChange(): void {
    window.addEventListener('storage', this.listener = (event) => {
      if (event.key === 'away' && (event.newValue !== event.oldValue)) {
        this.lock = storage.get('away');
        this.pauseReason.title = store.get('pause-title');
        this.dispatchTimer();
      }
    });
    document.addEventListener('awayChange', this.away = () => {
      this.lock = storage.get('away');
      this.pauseReason.title = store.get('pause-title');
      this.dispatchTimer();
    });
  }

  private dispatchTimer() {
    if (this.lock) {
      if(this.subscribes && this.subscribes.timer){
        this.subscribes.timer.unsubscribe();
        this.subscribes.timer = null;
      }
      this.startTimer();
    }
    else {
      try{
        this.subscribes.timer.unsubscribe();
        this.subscribes.timer = null;
      } catch(e) {}
    }
  }

  public getValue() {
    let defaultStatus = this.getDefaultActiveStatus();

    if (defaultStatus) {
      this.updateStatus(defaultStatus);
    } else {
      this.matDialog.open(LockBarSelectPauseComponent, {
        width: '400px',
        data: {}
      }).afterClosed().subscribe(res => {
        if(res) {
          this.onClickSetStatusBtn(res);
        }
      })
    }
  }

  public onClickSetStatusBtn(selectedStatus): void {
    const data = {
      status_id: selectedStatus.id,
      status_name: selectedStatus.name,
      system: parseInt(store.get('pause-id'), 10)
    };

    this.updateStatus(data);
  }

  private updateStatus(data) {
    this.disabled = true;
    store.set('away', false);
    document.dispatchEvent(new CustomEvent('awayChange'));

    this.pauseService.setPause(data).toPromise().then(() => {
      this.snackBar.open('Status updated!', 'Ok!', {
        duration: 6000
      });
      this.checkQuiz();
    }, (error) => {
      if (error.error) {
        for (const name in error.error) {
          if (error.error.hasOwnProperty(name)) {
            this.snackBar.open(error.error[name], 'Ok!', {
              duration: 6000,
              panelClass: ['error-snackbar']
            });
            break;
          }
        }
      }
    });
  }

  private getDefaultActiveStatus() {
    const id = store.get('pause-id');
    const callSystems = store.get('call-systems');

    if (
      callSystems[id] &&
      callSystems[id].settings &&
      callSystems[id].settings.default_active_status
    ) {
      return {
        status_id: callSystems[id].settings.default_active_status.id,
        status_name: callSystems[id].settings.default_active_status.name,
        system: parseInt(id, 10)
      }
    } else {
      return null;
    }
  }

  private checkQuiz() {
    this.quizService.onHold().toPromise().then((quiz) => {
      if (!quiz) { return; }
      store.set('quizOnHold', true);
      this.matDialog.open(QuizPopupComponent, {
        disableClose: true,
        data: { quiz, quizOn: 'quizOnHold' }
      })
    }, () => { })
  }

  private startTimer() {
    const timer = Observable.timer(1, 1000);

    this.subscribes.timer = timer.subscribe(
      t => {
        this.ticks = t;

        this.secondsDisplay = this.getSeconds(this.ticks);
        this.minutesDisplay = this.getMinutes(this.ticks);
        this.hoursDisplay = this.getHours(this.ticks);
      }
    );
  }

  private getSeconds(ticks: number) {
    return this.pad(ticks % 60);
  }

  private getMinutes(ticks: number) {
    return this.pad((Math.floor(ticks / 60)) % 60);
  }

  private getHours(ticks: number) {
    return this.pad(Math.floor((ticks / 60) / 60));
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  ngOnDestroy() {
    if (this.listener) {
      window.removeEventListener('storage', this.listener);
    }
    if (this.away) {
      document.removeEventListener('awayChange', this.away);
    }

    for (const key of this.subscribes) {
      if (this.subscribes[key] && this.subscribes[key].unsubscribe) {
        this.subscribes[key].unsubscribe();
      }
    }
  }

}
