import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {select, Store} from '@ngrx/store';
import Maps from '../../../helpers/Maps';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {Options} from '../../../helpers/Options';
import {LeadsService} from '../../../services/leads/leads.service';
import localStore from 'store';
import {User} from '../../../services/users/user';
import {UserService} from '../../../services/users/user.service';
import _get from 'lodash/get';
import NameGenerator from 'src/app/helpers/name-generator/name-generator';
import { generateId } from 'src/app/helpers/generate-id';
import {Router} from "@angular/router";
import {EventBusService} from '../../../services/event-bus/event-bus.service';
import {Email} from '../../../constants/regex';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-create-lead-modal',
  templateUrl: './create-lead-modal.component.html',
  styleUrls: ['./create-lead-modal.component.scss']
})
export class CreateLeadModalComponent implements OnInit, OnDestroy {

  public canCreateFakeLead = false;

  public createGroup: FormGroup;

  public createFakeGroup: FormGroup;

  public maps: any = new Maps();

  public options: any = new Options();

  public subscriber: any;

  public data: any = [];

  public loader: any = false;

  public birthday: any = [];

  public user: any;

  public is_fake = false;

  public errors = [];

  public campainSourceOpt: string[] = [
    'CVWebsite Agent',
    'CMGWebsite Agent',
    'MDCWebsite Agent',
    'USA DVWebsite Agent',
    'VDCWebsite Agent',
    'BCNWebsite Agent',
  ];
  public filteredOptions: Observable<string[]>;

  constructor(
    private self: MatDialogRef<CreateLeadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    private store: Store<any>,
    private matDialog: MatDialog,
    private leadsService: LeadsService,
    private eventBus: EventBusService,
    private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {
    this.user = localStore.get('user');
    this.createGroup = new FormGroup({
      first_name: new FormControl('', [
        Validators.required,
        Validators.min(3)
      ]),
      last_name: new FormControl('', []),
      phone: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(Email)
      ]),
      country_of_residence: new FormControl('', [
        Validators.required,
      ]),
      country_of_birth: new FormControl('', [
        Validators.required,
      ]),
      //
      assigned_to: new FormControl('', [
        Validators.required,
      ]),
      // product: new FormControl(''),
      status_reason: new FormControl('Unprocessed', [
        Validators.required,
      ]),
      // to 1990
      birthday: new FormControl('', []),

      password: new FormControl(''),

      campaign_source: new FormControl('', [
        Validators.required,
      ]),
      is_fake: new FormControl(false),
    });
  }

  ngOnInit() {
    const settings = localStore.get('settings');
    this.canCreateFakeLead = _get(settings, ['personal', 'can_create_fake_lead', 'value', 'value'], false) && !(this.dialog && this.dialog.type === 'inbound-call');

    this.yearsOfBirts();

    const storage = this.store.pipe(select((state) => {
      return {
        users: state.users,
        data: state.fields
      };
    })).subscribe((response) => {

      if (this.options.get('users') <= 0) {
        this.options.set('users', response.users.all);
      }

      if (Object.keys(this.maps.get('users')).length <= 0) {
        this.maps.set('users', response.users.map);
      }

      this.data = response.data;
    });

    if (storage) {
      storage.unsubscribe();
    }

    this.createFakeGroup = new FormGroup({
      first_name: new FormControl('', [
        Validators.required,
        Validators.min(3)
      ]),
      last_name: new FormControl(''),
      phone: new FormControl('', [
        Validators.min(3)
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      country_of_residence: new FormControl('', [
        // Validators.required,
      ]),
      country_of_birth: new FormControl('', [
        // Validators.required,
      ]),
      // to 1990
      birthday: new FormControl(this.birthday.find(el => el === 1990)),
      is_fake: new FormControl(true),
    });

    if (this.user.role !== User.ADMIN) {
      this.createGroup.get('assigned_to').patchValue(this.user.crm_id);
    }

    this.createGroup.get('password').patchValue(this.generatePassword());

    if (['mdc_dep', 'mdc_david', 'backoffice_mdc'].includes(this.user.role)) {
      this.createGroup.get('campaign_source').disable();
      this.createGroup.get('campaign_source').patchValue('MDC Consulting Client');
    } else {
      this.createGroup.get('campaign_source').patchValue('CVWebSite Agent');
    }

    if(this.dialog.not_lead){
      this.createGroup.get('campaign_source').patchValue('CVWebSite Agent Internet Call Form');
      this.canCreateFakeLead = false;
    }

    if (this.dialog && this.dialog.formData) {
      this.createGroup.patchValue({
        first_name: this.dialog.formData.first_name,
        last_name: this.dialog.formData.last_name,
        email: this.dialog.formData.lead_email,
        phone: this.dialog.formData.lead_phone,
      });
    }

    this.filteredOptions = this.createGroup.get('campaign_source').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnDestroy() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  public countryChange(name: string, form: FormGroup): void {
    if (form.get('country_of_residence') && form.get('country_of_birth')) {
      form.get('country_of_residence').setValue(name);
      form.get('country_of_birth').setValue(name);
    }
  }

  public setErrors(errors: any, form: FormGroup): void {
    if (form.get('phone')) {
      form.get('phone').setErrors(errors);
    }
  }

  public createReal(): void {
    // onSubmit Real
    const dialog = this.matDialog.open(ConfirmModalComponent, {
      width: '500px',
      hasBackdrop: true,
      data: {
        title: 'Create New Lead',
        message: 'Are you really want create ' + this.createGroup.value.first_name + ' ?'
      }
    });
    dialog.afterClosed().subscribe((result) => {
      this.loader = true;

      const formData = this.createGroup.getRawValue();

      if (!formData.birthday) {
        formData.birthday = new Date().getFullYear() - 20;
      }

      if(this.dialog.not_lead){
        formData.user_print = this.dialog.user_print;
      }

      const params = {};
      if (this.dialog && this.dialog.type === 'inbound-call') {
        params['inbound'] = true;
      }

      if (result) {
        this.subscriber = this.leadsService.create(
          formData,
          params
        ).subscribe((res) => {
          this.loader = false;

          const message = res.was_created ? 'Lead was successfully created!' : 'Lead with this email or phone number already exists!';

          this.snackBar.open(message, 'Great!', {
            duration: 5000,
          });

          if (this.dialog && this.dialog.type === 'inbound-call') {
            this.eventBus.emit('call-data', res);

            const call_data = localStore.get('call_data');
            if (call_data) {
              call_data.first_name =  res.first_name;
              call_data.last_name =  res.last_name;
              call_data.lead_phone =  res.phone;
              call_data.lead_number =  res.lead_number;
              call_data.lead_email =  res.email;
              call_data.lead_id =  res.id;
              call_data.lead_crm_id =  res.crm_id;

              localStore.set('call_data', call_data);
            }
          }

          if (res && res.id) {
            this.router.navigate([`/details/leads/${res.id}`]);
          } else {
            document.dispatchEvent(new CustomEvent('newLead',
              {
                detail: res,
              },
            ));
          }

          this.self.close(true);
        }, (err) => {
          if (err.error) {
            this.errors = []
            console.log('-----', err.error)
            this.createGroup.setErrors(err.error);
            for (const name in err.error) {
              if (err.error.hasOwnProperty(name)) {
                if (this.createGroup.get(name)) {
                  this.createGroup.get(name).setErrors({
                    err: err.error[name]
                  });
                } else {
                  this.errors.push(err.error[name]);
                }
                break;
              }
            }
            if (err.error.error) {
              let errorMessage = _get(err, ['error', 'error'], 'Something Went Wrong');
              if (String(errorMessage).includes('Email is invalid')) {
                errorMessage = 'Email is invalid';
              }
              if (String(errorMessage).includes('This email is already in use')) {
                errorMessage = 'This email is already in use';
              }
              this.snackBar.open(errorMessage, 'Dismiss!', {
                duration: 5000,
              });
            }
          }
          this.loader = false;
        });
      } else {
        this.loader = false;
      }
    });
  }

  public createFake(): void {
    // onSubmit Fake
    const dialog = this.matDialog.open(ConfirmModalComponent, {
      width: '500px',
      hasBackdrop: true,
      data: {
        title: 'Create New Lead',
        message: 'Are you really want create ' + this.createFakeGroup.value.first_name + ' ?'
      }
    });
    dialog.afterClosed().subscribe((result) => {

      this.errors = [];

      this.loader = true;

      if (result) {
        this.subscriber = this.leadsService.createFake(this.createFakeGroup.value).subscribe((res) => {
          this.loader = false;
          this.self.close(true);
          this.snackBar.open('Lead was successfully created!', 'Great!', {
            duration: 5000,
          });
          document.dispatchEvent(new CustomEvent('newLead',
            {
              detail: res,
            },
          ));
        }, (err) => {
          if (err.error) {
            this.createFakeGroup.setErrors(err.error);
            for (const name in err.error) {
              if (err.error.hasOwnProperty(name)) {
                if (this.createFakeGroup.get(name)) {
                  this.createFakeGroup.get(name).setErrors({
                    err: err.error[name]
                  });
                } else {
                  if (typeof err.error[name] === 'string') {
                    this.errors.push(err.error[name]);
                  }
                }
                break;
              }
            }
            if (err.error.error) {
              let errorMessage = _get(err, ['error', 'error'], 'Something Went Wrong');
              if (String(errorMessage).includes('Email is invalid')) {
                errorMessage = 'Email is invalid';
              }
              if (String(errorMessage).includes('This email is already in use')) {
                errorMessage = 'This email is already in use';
              }
              this.snackBar.open(errorMessage, 'Dismiss!', {
                duration: 5000,
              });
            }
          }
          this.loader = false;
        });
      } else {
        this.loader = false;
      }
    });
  }

  public async generateFirstName(evt?) {
    if (evt) { evt.stopPropagation(); }
    const firstName = await new NameGenerator(this.http).getFirstName();
    this.createFakeGroup.get('first_name').patchValue(firstName);
  }

  public async generateLastName(evt?) {
    if (evt) { evt.stopPropagation(); }
    const lastName = await new NameGenerator(this.http).getLastName();
    this.createFakeGroup.get('last_name').patchValue(lastName);
  }

  public async generateEmail(evt) {
    evt.stopPropagation();
    if (!this.createFakeGroup.get('first_name').value) { await this.generateFirstName(); }
    if (!this.createFakeGroup.get('last_name').value) { await this.generateLastName(); }
    const firstName = this.createFakeGroup.get('first_name').value.replaceAll(' ', ".");
    const lastName = this.createFakeGroup.get('last_name').value.replaceAll(' ', ".");;
    let email: string;
    const currEmail = this.createFakeGroup.get('email').value;
    if (currEmail.includes(firstName.toLowerCase()) && currEmail.includes(lastName.toLowerCase())) {
      email = `${generateId(5)}.${firstName}.${lastName}@fakelead.com`;
    } else {
      email = `${firstName}.${lastName}@fakelead.com`;
    }
    this.createFakeGroup.get('email').patchValue(email.toLowerCase());
  }

  public yearsOfBirts() {
    for (let i = (new Date().getFullYear() - 90); i < (new Date().getFullYear() - 18); i++) {
      this.birthday.push(i);
    }
    this.birthday.reverse();
  }

  public generatePassword() {
    return Math.random().toString(36).slice(-12).replace('.', '4');
  }

  public getAgentAvatar(crm_id: number): string {
    return this.userService.getAvatar(crm_id);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.campainSourceOpt.filter(option => option.toLowerCase().includes(filterValue));
  }
}
