export function isSameDayOrLaterTs(ts1, ts2, isDateTime = false): boolean {
  const date1 = new Date(ts1 * 1000);
  const date2 = new Date(ts2 * 1000);

  if (isDateTime) {
    // Check if date1 is the same or later than date2, considering year, month, day, hours, and minutes
    const sameDayAndTime =
        date1.getUTCFullYear() === date2.getUTCFullYear() &&
        date1.getUTCMonth() === date2.getUTCMonth() &&
        date1.getUTCDate() === date2.getUTCDate() &&
        date1.getUTCHours() === date2.getUTCHours() &&
        date1.getUTCMinutes() === date2.getUTCMinutes();

    const isLater =
        date1.getUTCFullYear() > date2.getUTCFullYear() ||
        (date1.getUTCFullYear() === date2.getUTCFullYear() &&
            (date1.getUTCMonth() > date2.getUTCMonth() ||
                (date1.getUTCMonth() === date2.getUTCMonth() &&
                    (date1.getUTCDate() > date2.getUTCDate() ||
                        (date1.getUTCDate() === date2.getUTCDate() &&
                            (date1.getUTCHours() > date2.getUTCHours() ||
                                (date1.getUTCHours() === date2.getUTCHours() &&
                                    date1.getUTCMinutes() > date2.getUTCMinutes())))))));

    return sameDayAndTime || isLater;
  } else {
    // Check if both timestamps are on the same day
    const sameDay =
      date1.getUTCFullYear() === date2.getUTCFullYear() &&
      date1.getUTCMonth() === date2.getUTCMonth() &&
      date1.getUTCDate() === date2.getUTCDate();

    // Check if timestamp1 is later or equal
    return sameDay || ts1 >= ts2;
  }
}
