import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import _get from 'lodash/get';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private api: ApiService) {
  }

  public sendLink(id, type, method, template): Observable<any> {
    return this.api.post('/payment/' + type + '/' + id + '/' + method, {
      body: Object.assign(template, _get(template, ['instalment', 'items']) ? {has_instalments: true} : {})
    });
  }

  public sendLinkV2(id, type, method, template): Observable<any> {
    return this.api.post('/payment/v2/' + type + '/' + id + '/' + method, {
      body: Object.assign(template, _get(template, ['instalment', 'items']) ? {has_instalments: true} : {})
    });
  }

  public checkEmail(related_id, related_type, email) {
    return this.api.post(`/payment/${related_type}/${related_id}/check/valid`, {
      body: {
        email: email
      }
    });
  }

  public detectType(method, account_number, products) {
    return this.api.post(`/payment/detect/${method}/${account_number}`, {
      body: {
        products
      }
    });
  }

  // public sendInvoice(id, type): Observable<any> {
  //   return this.api.post('/payment/invoice/' + id + '/' + type+'/send', {
  //   });
  // }

  public detectInstalment(product, lead_number) {
    return this.api.post(`/payment/detect-instalment/${lead_number}`, {
      body: {
        product
      }
    });
  }

  public getNotifications(params): Observable<any> {
    return this.api.get('/payment/notifications', {
      params
    });
  }

  public getAvailableMethods(country, accountNumber, campaignSource, paymentType): Observable<any> {
    return this.api.get(`/payment/available-methods?country=${country}&account_number=${accountNumber}&campaign_source=${campaignSource}&payment_type=${paymentType}`, {});
  }

  public getPartialOpportunities(accountNumber): Observable<any> {
    return this.api.get('/payment/detect-partial/' + accountNumber, {});
  }

  public makeFakePayment(data) {
    return this.api.post('/payment/fake-payment', {
      body: data
    });
  }

  public unlockCascading(data) {
    return this.api.post('/payment/unlock-cascading', {
      body: data
    });
  }

  public discount(opportunityId: number): Observable<any> {
    return this.api.post('/payment/discount/' + opportunityId, {});
  }

  public sendReceipt(id: string): Observable<any> {
    return this.api.post(`/payment/send-receipt/${id}`, {});
  }

  public getSpouses (accNumber: string): Observable<any> {
    return this.api.get('/spouses/' + accNumber, {});
  }
}
