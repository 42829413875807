import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockBarComponent } from './lock-bar.component';
import {LoaderModule} from '../../../general/loader/loader.module';
import {SmallLoaderModule} from '../../../general/small-loader/small-loader.module';
import { MatButtonModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatSnackBarModule, MatTooltipModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LockBarSelectPauseComponent } from './lock-bar-select-pause/lock-bar-select-pause.component';

@NgModule({
  declarations: [
    LockBarComponent,
    LockBarSelectPauseComponent
  ],
  exports: [
    LockBarComponent
  ],
  imports: [
    CommonModule,
    LoaderModule,
    MatDialogModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatSnackBarModule,
    SmallLoaderModule,
  ],
  entryComponents: [
    LockBarSelectPauseComponent,
    LockBarComponent,
  ]
})
export class LockBarModule { }
