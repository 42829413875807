export class User {

    static readonly ADMIN = 'admin';

    static readonly AGENT = 'agent';

    static readonly COLLECTION_AGENT = 'collection';

    static readonly RETENTION_AGENT = 'retention-agent';

    static readonly RETENTION_AGENTS_J = 'retention_agents_j';

    static readonly RETENTION_AGENT_MANAGER = 'retention_agent_manager';

    static readonly RETENTION_AGENT_MANAGER_J = 'retention_agent_manager_j';

    static readonly TEST_RETENTION_AGENT = 'test-retention-agent';

    static readonly MANAGEMENT_AGENT = 'management';

    static readonly REVIEW_AGENT = 'review';

    static readonly CSR_MANAGER = 'csr_manager';

    static readonly CSR_AGENT = 'csr-agent';

    static readonly FLOOR_MANAGEMENT = 'floor_management';

    static readonly QA_MANAGER = 'qa_manager';
    static readonly QA_SUBMISSIONS = 'qa_submissions';

    static readonly QA_AGENT = 'qa_agent';

    static readonly TOP_MANAGER = 'top_manager';

    static readonly CONVERSION_INTERVIEW = 'conversion_interview';
    static readonly CONVERSION_INTERVIEW_JHB = 'conversion_interview_jhb';

    static readonly CONVERSION_V2 = 'conversion_v2';
    static readonly CONVERSION_V3 = 'conversion_v3';

    static readonly LATAM = 'latam';
    static readonly LATAM_MANAGER = 'latam-manager';

    static readonly BACKOFFICE_MDC = 'backoffice_mdc';
    static readonly TEST_ADMIN = 'test_admin';
    static readonly MDC_DEP = 'mdc_dep';
    static readonly FILE_OPENING = 'file_opening_team';
    static readonly RETENTION_FILE_OPENING = 'retention_file_opening';
    static readonly WORKING_HOLIDAY_CONVERSION = 'working_holiday_conversion';
    static readonly CONVERSION_UA = 'conversion_ua';
    static readonly CONVERSION = 'agent';
    static readonly CONVERSION_INCUBATION = 'conversion-incubation';

    static readonly COLLECTION_DEP = 'collection';

    static readonly CURRENSY = 10.6;

    public avatar: number;

    public crm_id: number;

    public crm_api_key: string;

    public email: string;

    public commission: number;

    public full_name: string;

    public signature_url: string;

    public alias_name: string;

    public id: number;

    public level_points: number;

    public points: number;

    public role: string;

    public level: {
        id: number,
        name: string,
        points: number

    };

    public token: string;

    public username: string;

    public created_at: number;

    public updated_at: number;
}
