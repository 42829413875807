import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import store from 'store';

@Component({
  selector: 'app-lock-bar-select-pause',
  templateUrl: './lock-bar-select-pause.component.html',
  styleUrls: ['./lock-bar-select-pause.component.scss']
})
export class LockBarSelectPauseComponent implements OnInit {

  public selectedStatus = null;

  public options = store.get('call-systems')[store.get('pause-id')].pause_statuses || [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private dialogRef: MatDialogRef<LockBarSelectPauseComponent>
  ) {
  }

  onClickSetStatusBtn() {
    this.dialogRef.close(this.selectedStatus);
  }

  ngOnInit(): void {

  }

}
